#profile-main {
  background-color: white;
  min-height: 800px;
  padding: 50px 0;
  .city-option {
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 0 5px;
    //background-image: linear-gradient(180deg, #dfdfdf, transparent);
    cursor: pointer;
    margin: 1px 0;
  }
}
