.document-details {
  h1 {
    margin: 0 !important;
  }
  .file-name {
    padding: 12px 20px;
    border-radius: 10px;
    width: fit-content;
    margin-bottom: 30px;
    h1 {
      margin: 0;
      font-size: 30px;
      font-weight: bold;
    }
  }
  hr {
    margin: 70px 0;
  }
}
