.policy-complete-summary {
  margin-top: 50px;
  padding: 20px;
}
.policy-type {
  border-radius: 5px;
  border: 1px solid lightgrey;
  color: lightgrey;
  padding: 5px 8px;
  margin: 5px;
  text-align: center;
  &.active {
    background-color: #218c1b;
    color: white;
    border: 1px solid #1e7318;
  }
}
.policy-box {
  border: 1px solid lightgray;
  margin: 10px;
}
.policy-box {
  padding: 20px;
  p {
    font-size: 14px;
  }
  h1 {
    font-size: 24px;
    margin-bottom: 30px;
    text-decoration: underline;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 14px;
    font-weight: bold;
  }
}
.blink {
  animation: blinker 3s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
