.side-bar {
  padding: 20px;
  text-align: center;
  .welcome-box {
    color: white;
    font-weight: bold;
    padding: 5px 10px;
  }
  .profile-logout {
    border: 1px solid lightgray;
    margin-top: 50px;
  }
}
