.bot-chat {
  .chat {
    border: 1px solid rgb(191 249 198);
    border-radius: 10px;
    padding: 10px 20px;
    background-color: #ecffee;
    font-style: italic;
    width: 90%;
    margin: 10px 0 10px auto;
  }
  .chat-extras {
    text-align: right;
    color: lightgrey;
    div {
      float: right;
      color: lightgrey;
      margin-right: 5px;
      cursor: pointer;
      .selected {
        color: black;
      }
      .deselected {
        color: lightgrey;
      }
    }
  }
}
.you-chat {
  text-align: left;
}
.refresh-chat {
  text-align: right;
  margin: 0 0 10px auto;
  cursor: pointer;
  color: lightgrey;
  .selected {
    color: black;
  }
}
.historical-chat-container {
  width: fit-content;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.historical-chat-box {
  border: 1px solid #e8e8e8;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #f7f7f7;
  width: 48%;
  margin: 5px;
  text-decoration: none;
  color: black;
  min-width: 200px;
  -webkit-appearance: textarea;
  &:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
}
