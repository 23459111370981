.navbar-brand {
  margin: 0 40px 0 20px;
}
#main {
  min-height: 500px;
  h1 {
    margin: 30px 0;
  }
}
footer {
  padding-top: 20px;
  text-align: center;
  border-top: 1px solid #ececec;
  font-size: 14px;
  color: darkgray;
}
.nav-link {
  color: #dedede;
  &:hover {
    color: white;
  }
}
.loading::after {
  display: inline-block;
  animation: dotty steps(1,end) 1s infinite;
  content: '  ';
}
@keyframes dotty {
  0%   { content: '   '; }
  25%  { content: '.  '; }
  50%  { content: '.. '; }
  75%  { content: '...'; }
  100% { content: '   '; }
}
.loader {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: block;
  margin: 7px auto;
  position: relative;
  background: #FFF;
  box-shadow: -24px 0 #FFF, 24px 0 #FFF;
  box-sizing: border-box;
  animation: shadowPulse 2s linear infinite;
}

@keyframes shadowPulse {
  33% {
    background: #FFF;
    box-shadow: -24px 0 #000000, 24px 0 #FFF;
  }
  66% {
    background: #000000;
    box-shadow: -24px 0 #FFF, 24px 0 #FFF;
  }
  100% {
    background: #FFF;
    box-shadow: -24px 0 #FFF, 24px 0 #000000;
  }
}
.welcome-name {
  padding: 5px 10px;
  border: 1px darkgrey solid;
  border-radius: 5px;
  float: right;
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
  min-width: 250px;
  text-align: center;
}
.link {
  color: #4f4f4f;
  text-decoration: none;
  &:hover {
    color: #101010;
  }
}
.light-grey {
  color: lightgrey;
}
